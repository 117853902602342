@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

._wKo5X {
  display: grid;
  grid-template-columns: auto 200px;
  margin: 30px 0 20px 0;
}

._2_1sf {
  display: grid;
  grid-template-columns: auto 30%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 800px) {
  ._wKo5X {
    grid-template-columns: 100%;
  }

  ._2_1sf {
    grid-template-columns: 100%;
  }
}

._oGACf {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

@media only screen and (max-width: 600px) {
  ._oGACf {
    padding: 0;
  }
}

._1EWMa {
  width: 500px;
  border-radius: 10px;
  padding: 0 40px;
  margin: 0 auto;
  background-color: var(--playerBackground);
  overflow: auto;
  font-family: 'Quicksand', sans-serif;
}

@media only screen and (max-width: 768px) {
  ._1EWMa {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  ._1EWMa {
    padding: 0 20px;
  }
}

._3q4Ah {
  color: var(--titleColor);
  font-size: 28px;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  ._3q4Ah {
    width: 100%;
    font-size: 15px;
    margin-bottom: 0;
    text-align: center;
  }
}

._tAQkw {
  font-family: 'Varela Round', sans-serif;
  color: var(--timeColor);
  text-align: right;
  font-size: 30px;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  ._tAQkw {
    width: 100%;
    margin: 0 auto;
    display: none;
  }
}

._2cSIF {
  display: grid;
  place-items: center;
  width: 100%;
  margin-bottom: 20px;
}

._mIY65 {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;
}

._mIY65::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--progressSlider);
  cursor: pointer;
}

._mIY65::-webkit-slider-thumb:hover {
  background: var(--progressSlider);
}

._mIY65::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--progressSlider);
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  ._2cSIF {
    margin: 40px 0;
  }
}

._2FsrM {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(5, auto);
  place-items: center;
}

@media only screen and (max-width: 600px) {
  ._2FsrM {
    width: 100%;
    grid-template-columns: repeat(3, auto);
  }
}

._3CCfI {
  width: 26px;
  height: 26px;
  transition: transform 0.2s;
}

._3CCfI:hover {
  cursor: pointer;
  transform: scale(1.2);
}

@media only screen and (max-width: 600px) {
  ._3CCfI {
    display: none;
  }
}

._3Juzi {
  width: 50px;
  height: 50px;
  transition: transform 0.2s;
}

._3Juzi:hover {
  cursor: pointer;
  transform: scale(1.1);
}

._19ycA {
  width: 40px;
  height: 40px;
  transition: transform 0.2s;
}

._19ycA:hover {
  cursor: pointer;
  transform: scale(1.1);
}

._1mI0y {
  width: 40px;
  height: 40px;
  transition: transform 0.2s;
}

._1mI0y:hover {
  cursor: pointer;
  transform: scale(1.1);
}

._3a3Vy {
  width: 50px;
  height: 50px;
  transition: transform 0.2s;
}

._3a3Vy:hover {
  cursor: pointer;
  transform: scale(1.2);
}

._8c4KL {
  width: 26px;
  height: 26px;
  transition: transform 0.2s;
}

._8c4KL:hover {
  cursor: pointer;
  transform: scale(1.2);
}

@media only screen and (max-width: 600px) {
  ._8c4KL {
    display: none;
  }
}

._1cpXy {
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 60px;
}

._awgvT {
  -webkit-appearance: none;
  width: 70%;
  height: 3px;
  border-radius: 5px;
  background: var(--volumeSlider);
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;
}

._awgvT::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--volumeSlider);
  cursor: pointer;
}

._awgvT::-webkit-slider-thumb:hover {
  background: var(--volumeSlider);
}

._awgvT::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--volumeSlider);
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  ._1cpXy {
    display: none;
  }
}

._RZMQZ {
  width: 100%;
  margin: 20px auto 20px auto;
  max-height: 425px;
  min-height: 120px;
  overflow-x: hidden;
  padding-right: 10px;
  font-family: "Quicksand", sans-serif;
}

/* width */
._RZMQZ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
._RZMQZ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
._RZMQZ::-webkit-scrollbar-thumb {
  background: var(--primaryText);
  border-radius: 10px;
}

@media only screen and (min-width: 768px) {
  ._RZMQZ {
    width: 90%;
  }
}

._1l2NE {
  background-color: var(--playlistBackground);
  color: var(--playlistText);
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 16px;
  margin: 5px 0;
  padding: 3px 0;
  cursor: pointer;
  border-radius: 5px;
}

._1l2NE:first-child {
  margin: 0;
}

._2ybYQ {
  background-color: var(--playlistBackground);
  color: var(--playlistTextHoverActive);
  text-align: center;
  font-size: 18px;
  margin: 5px 0;
  padding: 3px 0;
  cursor: pointer;

  border-radius: 5px;
}

._2ybYQ:first-child {
  margin: 0;
}

._1l2NE:hover {
  background-color: var(--playlistBackground);
  color: var(--playlistTextHoverActive);
  border-radius: 5px;
}

._8a8Wj {
  display: none;
}

@media only screen and (min-width: 768px) {
  ._8a8Wj {
    width: 100%;
    margin: 20px auto;
    height: auto;
    color: var(--primaryText);
    display: inline-block;
    text-align: center;
  }
}

._1qrha {
  background-color: var(--tagsBackground);
  color: var(--tagsText);
  height: 40px;
  min-width: 100px;
  display: inline-grid;
  place-items: center;
  margin: 5px 5px;
  transition: transform 0.2s;
  padding: 0 10px;
  font-family: 'Varela Round', sans-serif;
  border-radius: 10px;
  font-size: 18px;
}

._2AaIQ {
  background-color: var(--tagsBackgroundHoverActive);
  color: var(--tagsTextHoverActive);
  height: 40px;
  min-width: 100px;
  display: inline-grid;
  place-items: center;
  margin: 0 5px;
  transition: transform 0.3s;
  padding: 0 10px;
  font-family: 'Varela Round', sans-serif;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;
}

._1qrha:hover {
  background-color: var(--tagsBackgroundHoverActive);
  color: var(--tagsTextHoverActive);
  cursor: pointer;
  transform: scale(1.1);
}

._3fz7s {
  text-align: center;
}

._1PreE {
  font-family: 'Quicksand', sans-serif;
  height: 40px;
  border: none;
  font-size: 18px;
  width: 100%;
  margin: 0 auto 10px auto;
  background-color: var(--searchBackground);
  color: var(--searchText);
  padding-left: 20px;
  border-radius: 10px;
}

._1PreE::-moz-placeholder {
  color: var(--searchPlaceHolder);
  opacity: 1;
}

._1PreE:-ms-input-placeholder {
  color: var(--searchPlaceHolder);
  opacity: 1;
}

._1PreE::placeholder {
  color: var(--searchPlaceHolder);
  opacity: 1;
}

._1PreE:-ms-input-placeholder {
  color: var(--searchPlaceHolder);
}

._1PreE::-ms-input-placeholder {
  color: var(--searchPlaceHolder);
}